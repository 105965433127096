<template>
  <v-container fluid>
    <v-card>
      <v-card-text>
        <v-text-field
          v-model="SMSGatewayUsername"
          @focus="showTouchKeyboard"
          :data-layout="KEYBOARD.KEYSETS.NORMAL"
          :disabled="loading"
          outlined
          dense
          :label="$t('settings.lang_smsGatewayUsername')"
        ></v-text-field>

        <v-text-field
          v-model="SMSGatewayAPIKey"
          @focus="showTouchKeyboard"
          :data-layout="KEYBOARD.KEYSETS.NORMAL"
          :disabled="loading"
          outlined
          dense
          :label="$t('settings.lang_smsGatewayAPIKey')"
        ></v-text-field>
      </v-card-text>

      <v-divider class="ma-0 pa-0" />

      <v-card-actions class="justify-end">
        <v-btn text color="error" :disabled="loading" @click="goBack">
          {{ $t("generic.lang_prev") }}
        </v-btn>
        <v-btn color="success" :disabled="loading" @click="saveData">
          {{ $t("generic.lang_save") }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard
        class="internalWidth"
        id="onScreenKeyboard"
        :options="touchKeyboard.options"
        :defaultKeySet="touchKeyboard.keySet"
        v-if="touchKeyboard.visible"
        :layout="touchKeyboard.layout"
        :cancel="hideTouchKeyboard"
        :accept="hideTouchKeyboard"
        :input="touchKeyboard.input"
      />
    </div>
  </v-container>
</template>

<script>
import { ENDPOINTS } from "../../../config";
import { Events } from "../../../plugins/events";
import mixin from "../../../mixins/KeyboardMixIns";

export default {
  name: "SMSGateway",
  components: {},
  mixins: [mixin],
  data() {
    return {
      ENDPOINTS,
      loading: false,
      SMSGatewayUsername: "",
      SMSGatewayAPIKey: "",
      // ----------------------------------
    };
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    getData() {
      this.loading = true;

      this.axios
        .post(ENDPOINTS.SETTINGS.MODULESETTINGS.SMS_GATEWAY.GET, {})
        .then((res) => {
          if (res.data.status === "success") {
            this.SMSGatewayUsername = res.data.SMSGatewayUsername;
            this.SMSGatewayAPIKey = res.data.SMSGatewayAPIKey;
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred"),
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    saveData: function () {
      this.axios
        .post(ENDPOINTS.SETTINGS.MODULESETTINGS.SMS_GATEWAY.UPDATE, {
          SMSGatewayUsername: this.SMSGatewayUsername,
          SMSGatewayAPIKey: this.SMSGatewayAPIKey,
        })
        .then((res) => {
          if (res.data.status === "SUCCESS") {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_savedSuccessfully"),
              color: "success",
            });
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch(() => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred"),
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    //GET DATA
    this.getData();
  },
};
</script>
