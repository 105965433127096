<template>
  <div>
    <page-title
      :heading="$t('settings.lang_smsGateway')"
      :subheading="$t('settings.lang_smsGateway')"
      :icon="icon"
    ></page-title>
    <div class="app-main__inner">
      <SMSGateway></SMSGateway>
    </div>
  </div>
</template>

<script>
import PageTitle from "../../../Layout/Components/PageTitle.vue";
import SMSGateway from "../../../components/settings/moduleSettings/SMSGateway.vue";

export default {
  components: {
    PageTitle,
    SMSGateway,
  },

  data: () => ({
    icon: "pe-7s-plane icon-gradient bg-tempting-azure",
  }),
};
</script>
